import axios from 'axios';
import { ParticipatingFieldsEntity, IParticipatingAnswer, IEventBrand } from '@/models/brand';
import { IEventV2 } from '@/models/event';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const getEventBrandFields = (event: IEventV2) => new Promise((resolve: Resolve<IEventBrand>, reject) => {
  axios
    .get(`${url}/event/brand/${event.slug}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addEventBrand = (event: IEventV2, form: { company_name: string, participating_fields: ParticipatingFieldsEntity[] }) => new Promise((resolve: Resolve<IEventBrand>, reject: any) => {
  axios
    .post(`${url}/event/brand/${event.slug}/register_brand`, form, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getEventBrandAnswers = (exhibitorId: number) => new Promise((resolve: Resolve<IData<IParticipatingAnswer>>) => {
  axios
    .get(`${url}/v2/participating-answers?page_size=100&exhibitor=${exhibitorId}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const updateEventBrandAnswers = (eventId: number, exhibitorId: number, fields: ParticipatingFieldsEntity[]) => new Promise((resolve: Resolve<IData<IParticipatingAnswer>>) => {
  axios
    .post(`${url}/organizers/events/${eventId}/brands/${exhibitorId}/participating_answers`, { fields }, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
