import { render, staticRenderFns } from "./Completed.vue?vue&type=template&id=b7cbbf06&"
import script from "./Completed.vue?vue&type=script&lang=ts&"
export * from "./Completed.vue?vue&type=script&lang=ts&"
import style0 from "./Completed.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports