































































import { MutationPayload } from 'vuex';
import { ParticipatingFieldsEntity } from '@/models/brand';
import { addEventBrand } from '@/api/brands';
import { uploadFile } from '@/api/files';
import { getExhibitorById } from '@/api/exhibitors';
import { IExhibitor } from '@/models/exhibitor';
import store from '@/store';
import EventHome from '@/views/Event/Home.vue';
import BasicInformation from './BasicInformation.vue';
import Review from './Review.vue';
import { fields, form } from '.';
import ManagedFields from './ManagedFields.vue';
import Completed from './Completed.vue';
import Login from './Login.vue';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

export default defineComponent({
  components: { EventHome },
  data() {
    return {
      stepNames: [
        'Autenticação',
        'Informação básica',
        'Informação adicional',
        'Revisão',
        'Terminado!',
      ],
      activeComponent: 0,
      fields: fields as ParticipatingFieldsEntity[],
      form: form,
      components: [Login, BasicInformation, ManagedFields, Review, Completed],
    };
  },
  computed: {
    progressWidth(): number {
      return this.activeComponent === 0 ? 0 : 100 / (this.components.length - this.activeComponent);
    },
  },
  setup() {
    const { contextEvent, translations } = useContext();
    const { userLoggedIn } = useAuth();
    return {
      contextEvent,
      userLoggedIn,
      translations,
    };
  },
  created() {
    this.stepNames = [
      this.translations.exhibitors.onboarding.step_authentication,
      this.translations.exhibitors.onboarding.step_basicInfo,
      this.translations.exhibitors.onboarding.step_additionalInfo,
      this.translations.exhibitors.onboarding.step_review,
      this.translations.exhibitors.onboarding.step_done,
    ];
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.stepNames = [
          this.translations.exhibitors.onboarding.step_authentication,
          this.translations.exhibitors.onboarding.step_basicInfo,
          this.translations.exhibitors.onboarding.step_additionalInfo,
          this.translations.exhibitors.onboarding.step_review,
          this.translations.exhibitors.onboarding.step_done,
        ];
      }
    });

    if (this.userLoggedIn) {
      this.activeComponent = 1;
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setUserLogin') {
          this.activeComponent = 1;
          unsubscribe();
        }
      });
    }
  },
  methods: {
    next() {
      this.activeComponent += 1;
    },

    back() {
      if (!this.userLoggedIn || this.activeComponent !== 1) {
        this.activeComponent -= 1;
      }
    },

    updateParticipatingFields(fields: ParticipatingFieldsEntity[]) {
      this.fields = fields;
    },

    updateBasicInfo(form: IExhibitor) {
      this.form = form;
    },

    async submit(callback?: () => void) {
      for (const [i, field] of this.fields.entries()) {
        if (
          field.participating_field &&
          field.participating_field.field_type === 'file' &&
          field.value
        ) {
          const imageForm = new FormData();
          imageForm.append('file', field.value as string);
          // eslint-disable-next-line no-await-in-loop
          const response = await uploadFile(imageForm);

          this.fields[i] = {
            ...field,
            value: response.data.id,
          };
        }
      }

      this.form.event = this.contextEvent.id;

      addEventBrand(this.contextEvent, {
        company_name: this.form.name ? this.form.name : '',
        participating_fields: this.fields,
      })
        .then((response) => {
          const exhibitor = response.data;
          if (this.userLoggedIn) {
            getExhibitorById(exhibitor.id)
              .then((response) => {
                store.commit('setContextExhibitor', response.data);
                this.activeComponent += 1;
              })
              .catch((err) => {
                if (callback) {
                  callback();
                }
                store.commit('addPopup', {
                  type: 'danger',
                  message: err.message,
                  autohide: true,
                });
              });
          } else {
            this.activeComponent += 1;
          }
        })
        .catch((err) => {
          if (callback) {
            callback();
          }
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: this.translations.common.user_chat_failed,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },
  },
});
