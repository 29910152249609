import {
  IInput, ITextarea, IRadio, ICheckbox, ISelect, IDescription,
} from '@/components/form/index';
import { ParticipatingFieldsEntity } from '@/models/brand';

import { IPopup, IModal } from '@/components';
import { IExhibitor } from '@/models/exhibitor';
import { ITranslations } from '@/translations';
import store from '@/store';

export interface IOnboardingForm {
  [key: string]: string
}

export const participatingInputs: Array<IInput | IRadio | ICheckbox | ITextarea | ISelect | IDescription> = [];

export const fields: Array<ParticipatingFieldsEntity> = [];

export const convertBridgeTitles = (title: string) => {
  const translations: ITranslations = store.getters.getTranslations;

  if (title === 'BridgeMeetingRoom') {
    return translations.exhibitors.onboarding.convert_titles.meeting_room;
  }
  if (title === 'BridgeLogo') {
    return translations.exhibitors.onboarding.convert_titles.logo;
  }
  if (title === 'BridgeProfilePic') {
    return translations.exhibitors.onboarding.convert_titles.profile_pic;
  }
  if (title === 'BridgeEmbeddedVideo') {
    return translations.exhibitors.onboarding.convert_titles.embedded;
  }
  return title;
};

export interface IOnboardingInputs {
  name: IInput;
  dynamicField: IInput;
}
export interface IOnboardingAuthInputs {
  name: IInput;
  password: IInput;
  confirmPassword: IInput;
  email: IInput;
}

export const form: Partial<IExhibitor> = {
  name: '',
  event: 0,
};
export const authForm: { password: string; confirmPassword: string, email: string, name: string } = {
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
};

export const authInputs = () => {
  const translations: ITranslations = store.getters.getTranslations;
  const inputs: IOnboardingAuthInputs = {
    name: {
      id: 'exhibitor-onboarding-name',
      name: 'exhibitor_onboarding_name',
      type: 'text',
      label: translations.common.name,
      placeholder: translations.exhibitors.onboarding.login.exhibitor_manager_name,
      value: '',
    },
    email: {
      id: 'exhibitor-onboarding-email',
      name: 'exhibitor_onboarding_email',
      type: 'text',
      label: 'Email',
      placeholder: translations.exhibitors.onboarding.login.exhibitor_manager_email,
      value: '',
    },
    password: {
      id: 'exhibitor-onboarding-password',
      name: 'exhibitor_onboarding_password',
      type: 'password',
      label: translations.auth.login.password,
      placeholder: translations.auth.login.password_placeholder,
      value: '',
    },
    confirmPassword: {
      id: 'exhibitor-onboarding-confirm-password',
      name: 'exhibitor_onboarding_confirm_password',
      placeholder: translations.account.settings.password_confirm_placeholder,
      type: 'password',
      label: translations.account.settings.password_confirm,
      value: '',
    },
  };
  return inputs;
};

export const inputs = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const inputs: IOnboardingInputs = {
    name: {
      id: 'exhibitor-onboarding-name',
      name: 'exhibitor_onboarding_name',
      type: 'text',
      label: translations.exhibitors.onboarding.basicInformation.exhibitor_name,
      placeholder: translations.exhibitors.onboarding.basicInformation.exhibitor_name_placeholder,
      value: '',
    },

    dynamicField: {
      id: 'exhibitor-onboarding-dynamic',
      name: 'exhibitor_onboarding_dynamic',
      placeholder: '',
      type: 'text',
      label: '',
      value: '',
    },
  };
  return inputs;
};

export const onboardingPopup: IPopup = {
  message: '',
  type: 'danger',
};

export const stringsForName = [
  'name',
  'nombre',
  'nome',
];

export const basicInformation = [
  ...stringsForName,
  'email',
  'e-mail',
];

export const modal: IModal = {
  isActive: false,
};
