






































import { MutationPayload } from 'vuex';
import Input from '@/components/form/input.vue';
import { getExhibitorsByName } from '@/api/exhibitors';
import router from '@/router';
import { getEventV1 } from '@/api/events';
import store from '@/store';
import { inputs, form } from './index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

export default defineComponent({
  components: {
    Input,
  },
  data() {
    return {
      inputs: inputs(),
      form: form,
      loading: false,
      formTitle: '',
      formDescription: '',
      event: 0,
    };
  },
  setup() {
    const { contextEvent, translations } = useContext();
    const { userLoggedIn } = useAuth();

    return {
      contextEvent,
      translations,
      userLoggedIn,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);

    this.inputs = inputs();
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.inputs = inputs();
        if (this.contextEvent.slug) {
          getEventV1(this.contextEvent.slug).then((response) => {
            this.formTitle = response.data.participating_form_title;
            this.formDescription = response.data.participating_form_description;
          });
        }
      }
    });
    if (this.contextEvent.slug) {
      getEventV1(this.contextEvent.slug).then((response) => {
        this.formTitle = response.data.participating_form_title;
        this.formDescription = response.data.participating_form_description;

        // If exhibitor form isn't published, it must not be available to users
        if (response.data.settings.exhibiting_form_status !== 'Published') {
          router.push('/');
        }
      });
    } else {
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent' && this.contextEvent.slug) {
          getEventV1(this.contextEvent.slug).then((response) => {
            this.formTitle = response.data.participating_form_title;
            this.formDescription = response.data.participating_form_description;
            // If exhibitor form isn't published, it must not be available to users
            if (response.data.settings.exhibiting_form_status !== 'Published') {
              router.push('/');
            }
          });
        }
      });
    }

    // Whenever the user types, the error is reset
    this.inputs.name.onInput = (value) => {
      if (this.inputs.name.error) {
        this.inputs.name.error = false;
        this.inputs.name.message = '';
        this.inputs.name.value = value;
      }
    };
  },
  methods: {
    emitForm() {
      this.$emit('updateBasicInfo', this.form);
    },

    next() {
      this.loading = true;
      if (this.form.name) {
        getExhibitorsByName(this.form.name, this.event).then((response) => {
          const exhibitors = response.data.results;
          if (this.form.name) {
            if (exhibitors.length > 0) {
              this.inputs.name = {
                ...this.inputs.name,
                value: this.form.name,
                error: true,
                message: this.translations.common.name_in_use,
              };
              this.loading = false;
              this.$forceUpdate(); // Sometimes this line is necessary to trigger the update of the view
            } else {
              this.loading = false;
              this.inputs.name = {
                ...this.inputs.name,
                value: this.form.name,
                loading: false,
              };
              if (this.userLoggedIn) {
                this.$emit('next');
              }
            }
          }
        });
      }
    },
  },
});
