



















































































































import { MutationPayload } from 'vuex';
import { getEventBrandFields } from '@/api/brands';
import { ParticipatingFieldsEntity } from '@/models/brand';
import Textarea from '@/components/form/textarea.vue';
import Radios from '@/components/form/radios.vue';
import Checkboxes from '@/components/form/checkboxes.vue';
import Select from '@/components/form/select.vue';
import Description from '@/components/form/description.vue';
import Cropper from '@/components/form/cropper.vue';
import Input from '@/components/form/input.vue';
import { IOption } from '@/components/form';
import firstLetterUpperCase from '@/utils/firstLetterUppercase';
import isEmail from '@/utils/isEmail';
import isUrl from '@/utils/isUrl';
import store from '@/store';
import { IModal } from '@/components';
import Modal from '@/components/modal.vue';
import { convertBridgeTitles, participatingInputs } from './index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

export default defineComponent({
  components: {
    Textarea,
    Input,
    Radios,
    Checkboxes,
    Select,
    Description,
    Cropper,
    Modal,
  },
  data() {
    return {
      modal: {
        isActive: false,
        isCard: true,
      } as IModal,

      cropper: {
        image: '',
        onSelect: (blob: string) => {
          console.log(blob);
        },
        aspectRatio: null as any,
      },

      fields: [] as ParticipatingFieldsEntity[] | null | undefined,

      participatingInputs: participatingInputs,

      convertBridgeTitles: convertBridgeTitles,

      loading: false,

      cropFieldId: 0,

      cropping: true,

      croppedFileName: '',

      confirming: false,

      cropSrc: '',
    };
  },
  computed: {
    disabled(): boolean {
      if (this.fields) {
        const mandatoryLength = this.fields.filter(
          (field) => field.participating_field.mandatory && this.fieldDependency(field),
        ).length;
        return !(
          this.fields.filter(
            (field) =>
              field.participating_field.mandatory &&
              this.fieldDependency(field) &&
              field.value &&
              field.value !== '',
          ).length === mandatoryLength
        );
      }
      return false;
    },
  },
  setup() {
    const { contextEvent, translations } = useContext();
    const { userLoggedIn } = useAuth();

    return {
      contextEvent,
      translations,
      userLoggedIn,
    };
  },
  created() {
    this.loading = true;
    this.cropper.onSelect = this.onSelectCrop;
    if (this.contextEvent.id) {
      this.getEventBrandForm();
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent') {
          this.getEventBrandForm();
          unsubscribe();
        }
      });
    }
  },
  methods: {
    cancel() {
      this.modal.isActive = false;
    },

    cancelCrop() {
      this.cropping = true;
      this.confirming = false;
    },

    onSelectCrop(blob: string) {
      if (this.fields) {
        const file = new File([blob], this.croppedFileName, { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        this.cropSrc = urlCreator.createObjectURL(blob);
        this.fields = this.fields.map((field) => {
          if (field.id === this.cropFieldId) {
            return {
              ...field,
              value: file as any,
            };
          }
          return field;
        });
        this.cropping = false;
        this.confirming = true;
      }
    },

    confirmCrop() {
      this.modal.isActive = false;
      this.$emit('updateParticipatingFields', this.fields);
    },

    selectImage(file: File) {
      this.croppedFileName = file.name;
      if (this.fields) {
        const field = this.fields.find((field) => field.id === this.cropFieldId);
        if (field) {
          if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
            if (field.participating_field.validation === 'square_image') {
              this.cropper = {
                ...this.cropper,
                aspectRatio: 1 / 1,
              };
            } else {
              this.cropper = {
                ...this.cropper,
                aspectRatio: null,
              };
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e && e.target && e.target.result) {
                this.cropper = { ...this.cropper, image: e.target.result as string };
                this.cropping = true;
                this.confirming = false;
                this.modal.isActive = true;
              }
            };
            reader.readAsDataURL(file);
          } else {
            const index = this.fields.indexOf(field);
            this.participatingInputs[index] = {
              ...this.participatingInputs[index],
              value: '',
              message: this.translations.common.file_not_image,
              error: true,
            };
            this.$forceUpdate();
          }
        }
      }
    },

    fieldDependency(field: ParticipatingFieldsEntity) {
      if (this.fields && field.participating_field.dependence_field) {
        const fieldDepending = this.fields.find(
          (dField) => field.participating_field.dependence_field === dField.participating_field.id,
        );
        if (fieldDepending && field.participating_field.dependence_operator === '==') {
          if (field.participating_field.dependence_value.includes(',')) {
            const allowedValues = field.participating_field.dependence_value.split(',');
            let dependencyValid = false;
            allowedValues.forEach((value) => {
              dependencyValid = dependencyValid || value === fieldDepending.value;
            });
            return dependencyValid;
          }
          if (fieldDepending.value === field.participating_field.dependence_value) {
            return true;
          }
          return false;
        }
        return false;
      }
      return true;
    },

    getEventBrandForm() {
      getEventBrandFields(this.contextEvent)
        .then((response) => {
          if (response.data.participating_fields) {
            this.fields = response.data.participating_fields;
            if (this.fields && this.fields.length > 0) {
              // Fields are sorted by custom order and the name field is removed if exists
              this.fields = this.fields.sort((prev, next) => {
                if (prev.order < next.order) {
                  return -1;
                }
                return 1;
              });

              let optionsValues: Array<string>;

              this.fields.forEach((field, i) => {
                const participatingField = field.participating_field;
                if (participatingField) {
                  const fieldType = participatingField.field_type;
                  const fieldValidation = participatingField.validation;

                  let title;
                  if (participatingField.show_title && participatingField.title) {
                    title = firstLetterUpperCase(
                      this.convertBridgeTitles(participatingField.title),
                    );
                  }
                  if (
                    (fieldType === 'select' || fieldType === 'radio' || fieldType === 'checkbox') &&
                    participatingField.options
                  ) {
                    optionsValues = participatingField.options
                      .split(',')
                      .map((string) => string.trim());

                    const options: IOption[] = optionsValues.map((option) => ({
                      value: option,
                      label: firstLetterUpperCase(option),
                      checked: false,
                    }));

                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      // We need the custom field to have a default value for the select
                      message: participatingField.mandatory
                        ? this.translations.common.mandatory_field
                        : undefined,
                      value: options[0].value,
                      options,
                    });
                  } else if (
                    fieldType === 'description' ||
                    fieldType === 'url' ||
                    fieldType === 'label'
                  ) {
                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      // For some reason, description's field value is saved in 'options'
                      value: participatingField.options,
                    });
                  } else if (fieldType === 'file' && fieldValidation.includes('image')) {
                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      // For some reason, description's field value is saved in 'options'
                      value: participatingField.options,
                      message: participatingField.mandatory
                        ? this.translations.common.mandatory_field
                        : undefined,
                      onFileSelect: (file: File) => {
                        if (this.participatingInputs[i].error) {
                          this.participatingInputs[i] = {
                            ...this.participatingInputs[i],
                            error: false,
                            message: '',
                          };
                        }
                        this.cropFieldId = field.id;
                        this.selectImage(file);
                      },
                    });
                  } else {
                    const index = i;
                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      message: participatingField.mandatory
                        ? this.translations.common.mandatory_field
                        : undefined,
                      onInput: (value: string | number) => this.clearInput(index, value),
                      value: '',
                    });
                  }
                }
              });

              this.loading = false;
            }
          }
        })
        .catch((err) => {
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: this.translations.common.user_chat_failed,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },

    updateParticipatingFields() {
      if (this.fields) {
        this.$emit(
          'updateParticipatingFields',
          this.fields.map((field) => ({
            ...field,
            value: this.fieldDependency(field) ? field.value : null,
          })),
        );
      }
    },

    clearInput(index: number, value: string | number) {
      if (this.participatingInputs[index].error) {
        this.participatingInputs[index] = {
          ...this.participatingInputs[index],
          error: false,
          message: '',
          value: value as string,
        };
        this.$forceUpdate();
      }
    },
    next() {
      let formInvalid = false;

      if (this.fields) {
        this.fields.forEach((field, i) => {
          if (!field.participating_field) {
            return false;
          }
          if (participatingInputs[i] && this.fieldDependency(field)) {
            if (
              field.participating_field.field_type === 'checkbox' &&
              !(field.value as any).some((option: any) => option.checked)
            ) {
              this.participatingInputs[i] = {
                ...this.participatingInputs[i],
                error: true,
                message: this.translations.common.emptyFieldNotAllowed,
              };
              this.$forceUpdate();
              formInvalid = true;
            } else if (
              field.participating_field.mandatory &&
              (!field.value || field.value === '')
            ) {
              this.participatingInputs[i] = {
                ...this.participatingInputs[i],
                error: true,
                message: this.translations.common.emptyFieldNotAllowed,
              };
              this.$forceUpdate();
              formInvalid = true;
            } else if (field.participating_field.validation === 'url') {
              if (!field.value || field.value === '') {
                if (field.participating_field.mandatory) {
                  this.participatingInputs[i] = {
                    ...this.participatingInputs[i],
                    error: true,
                    message: this.translations.common.emptyFieldNotAllowed,
                  };
                  this.$forceUpdate();
                  formInvalid = true;
                }
              } else if (!isUrl(field.value as string)) {
                this.participatingInputs[i] = {
                  ...this.participatingInputs[i],
                  value: field.value as string,
                  error: true,
                  message: 'URL inválido',
                };
                formInvalid = true;
                this.$forceUpdate();
              }
            } else if (field.participating_field.validation === 'email') {
              if (!field.value || field.value === '') {
                if (field.participating_field.mandatory) {
                  this.participatingInputs[i] = {
                    ...this.participatingInputs[i],
                    error: true,
                    message: this.translations.common.emptyFieldNotAllowed,
                  };
                  this.$forceUpdate();
                  formInvalid = true;
                }
              } else if (!isEmail(field.value as string)) {
                this.participatingInputs[i] = {
                  ...this.participatingInputs[i],
                  value: field.value as string,
                  error: true,
                  message: 'Email inválido',
                };
                formInvalid = true;
                this.$forceUpdate();
              }
            }
          }
          return true;
        });

        if (!formInvalid) {
          this.$emit('next');
        } else {
          window.scrollTo(0, 0);
        }
      }
    },
  },
});
