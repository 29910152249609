






























import { getEventV1 } from '@/api/events';
import store from '@/store';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

export default defineComponent({
  data() {
    return {
      finalFormMessage: '',
    };
  },
  setup() {
    const { contextEvent, contextExhibitor, translations } = useContext();
    const { userLoggedIn } = useAuth();

    return {
      contextEvent,
      contextExhibitor,
      translations,
      userLoggedIn,
    };
  },
  created() {
    getEventV1(this.contextEvent.slug).then((response) => {
      this.finalFormMessage = response.data.participating_final_form_message;
    });
    store.dispatch('getRoles');
  },
});
