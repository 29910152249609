


















































































import { ParticipatingFieldsEntity } from '@/models/brand';
import { IExhibitor } from '@/models/exhibitor';
import { defineComponent, PropType } from '@vue/composition-api';
import useContext from '@/composition/context';
import { convertBridgeTitles } from '.';

export default defineComponent({
  props: {
    fields: {
      type: Array as PropType<ParticipatingFieldsEntity[]>,
      required: true,
    },
    form: {
      type: Object as PropType<IExhibitor>,
      required: true,
    },
  },
  setup() {
    const { translations } = useContext();
    return { translations };
  },
  data() {
    return {
      URL: window.URL,
      loading: false,
      convertBridgeTitles: convertBridgeTitles,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$emit('submit', () => {
        // If the submition fails and the component isn't changed to "Completed" we still want the loading to stop
        this.loading = false;
      });
    },
  },
});
