import {
  computed,
} from '@vue/composition-api';
import store from '@/store';
import { IAuthState } from '@/store/modules/auth';
import { IUser } from '@/models/user';

// GETTERS

const userLoggedIn = computed(() => <boolean>store.getters.userLoggedIn);
const userAuthState = computed(() => <IAuthState>store.getters.userAuthState);
const currentUser = computed(() => <IUser>store.getters.currentUser);

export default function useAuth() {
  return {
    userAuthState,
    currentUser,
    userLoggedIn,
  };
}
