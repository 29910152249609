





















































import { MutationPayload } from 'vuex';
import Input from '@/components/form/input.vue';
import { getEventById, getEventV1 } from '@/api/events';
import store from '@/store';
import { addUser } from '@/api/users';
import router from '@/router';
import { getRoles } from '@/api/auth';
import { authForm, authInputs } from './index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Input,
  },
  data() {
    return {
      inputs: authInputs(),
      form: authForm,
      registerUser: null as boolean | null,
      loading: false,
      event: 0,
      redirectUrl: process.env.VUE_APP_REDIRECT_URL.substring(
        0,
        process.env.VUE_APP_REDIRECT_URL.length - 2,
      ),
    };
  },
  setup() {
    const { translations, language } = useContext();
    return { translations, language };
  },
  created() {
    this.inputs = authInputs();
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.inputs = authInputs();
      }
    });

    // If exhibitor form is not published, redirect immediately
    const event = parseInt(this.$route.params.event, 10);
    getEventById(event).then((response) => {
      const event = response.data;
      getEventV1(event.slug).then((response) => {
        if (response.data.settings.exhibiting_form_status !== 'Published') {
          router.push('/login');
        }
      });
    });
  },
  methods: {
    login() {
      this.loading = true;
      const { email, password } = this.form;
      const user = {
        email: email.toLowerCase(),
        password: password && password !== '' ? password : '_',
      };
      store.dispatch('loginUser', user);
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setUserLogin') {
          this.loading = false;
          this.registerUser = false;
          unsubscribe();
        }

        if (mutation.type === 'setUserAuthError') {
          this.loading = false;
          if (mutation.payload === 'no-account') {
            this.registerUser = true;
            this.inputs.password = {
              ...this.inputs.password,
              value: this.form.password,
              error: false,
            };
          } else if (mutation.payload === 'wrong-password') {
            this.registerUser = false;
            if (user.password && user.password !== '_') {
              this.inputs.password = {
                ...this.inputs.password,
                error: true,
                message: this.translations.auth.login.wrong_password,
              };
            }
          }
          unsubscribe();
        }
      });
    },

    register() {
      if (this.form.password !== this.form.confirmPassword) {
        this.inputs.confirmPassword = {
          ...this.inputs.confirmPassword,
          value: this.form.confirmPassword,
          error: true,
          message: this.translations.auth.register.password_not_match,
        };
      } else if (this.form.email === '') {
        this.inputs.email = {
          ...this.inputs.email,
          value: this.form.email,
          error: true,
          message: this.translations.common.mandatory_field,
        };
      } else if (this.form.name === '') {
        this.inputs.name = {
          ...this.inputs.name,
          value: this.form.name,
          error: true,
          message: this.translations.common.mandatory_field,
        };
      } else {
        const { email, password, name } = this.form;

        const user = {
          name,
          email,
          password,
        };
        this.loading = true;

        addUser(user)
          .then((response) => {
            if (response.status === 201) {
              store.dispatch('loginUser', user);
              const unsubscribe = store.subscribe((mutation: MutationPayload) => {
                if (mutation.type === 'setUserLogin') {
                  getRoles().then((response) => {
                    const v2 = response.data;
                    this.loading = false;
                    store.commit('setRoles', v2);
                  });
                  const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

                  localStorage.setItem(
                    localStorageProp,
                    JSON.stringify({
                      auth: mutation.payload,
                      userLoggedIn: true,
                    }),
                  );
                  unsubscribe();
                }
              });
            }
          })
          .catch((err) => {
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: this.translations.common.user_chat_failed,
                type: 'danger',
                autohide: true,
              });
            }
          });
      }
    },
  },
});
